import WarningIcon from '@mui/icons-material/Warning'
import { Flex } from 'components/shared/flex'
import { ProofreadInfo } from 'entities/proofread_info'
import moment from 'moment'
import React from 'react'
import { ViewModel } from 'view_models'

export class ProofreadInfoViewModel extends ViewModel<ProofreadInfo> {
  public getLastResultDate = () => {
    if (!this.object.baseDate) return '-'
    return moment(this.object.baseDate).format('YYYY/MM')
  }

  public getCycle = () => {
    if (!this.object.cycle) return '-'
    return `${this.object.cycle} ${this.object.cycleUnit === 'month' ? 'ヶ月' : '年'}`
  }

  public getExpiredDate = () => {
    if (!this.object.expiredAt) return '-'
    return moment(this.object.expiredAt).format('YYYY/MM')
  }

  public getInstrumentImage = () => {
    return this.object.instrumentBasicInfo?.imageUrl
  }

  public getSupplier = (): string => {
    if (this.object.supplierName) {
      return this.object.supplierName
    } else {
      return this.object.supplier?.name || '-'
    }
  }

  public getPrice = () => {
    return this.object.price?.toLocaleString() || '-'
  }
}

import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Collapse, Stack, Tooltip } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { usePartnerPostInstrumentApi, usePartnerPatchInstrumentApi, usePartnerDeleteInstrumentApi } from 'api/partner/instrument'
import { usePartnerDeleteUnmanagedInstrumentApi, usePartnerRestoreManagementUnmanagedInstrumentApi } from 'api/partner/unmanaged_instrument'
import { useDeleteAttachmentsApi } from 'api/shared/attachments'
import { useDeleteInstrumentImageApi } from 'api/shared/instrument_image'
import { DeletedLabel } from 'components/partner/instrument/deleted_label'
import { DisabledRestoreManagementButton } from 'components/partner/instrument/disabled_restore_management_button'
import { NoSelectableOutsideLaboratory } from 'components/partner/instrument/no_selectable_outside_laboratory'
import { CheckboxAccordion } from 'components/shared/checkbox_accordion'
import AttachmentsList from 'components/shared/data/attachments_list'
import { Flex } from 'components/shared/flex'
import {
  StringField,
  NumberField,
  SelectField,
  ImageInput,
  MonthField,
  FilesInput,
  AutocompleteSelectField,
  DateField,
} from 'components/shared/form/input'
import { ProofreadAlarmForm } from 'components/shared/proofread_alarm_form'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { FileAttachment } from 'entities/file_attachment'
import { InstrumentForm, Instrument, cantEditProofreadInfoMessage } from 'entities/instrument'
import { InstrumentAlarmAttributeForm } from 'entities/instrument_alarm_attribute'
import { InstrumentAttachment } from 'entities/instrument_attachment'
import { InstrumentCategory } from 'entities/instrument_category'
import { Laboratory } from 'entities/laboratory'
import { OutsideLaboratory } from 'entities/outside_laboratory'
import { warningDiscardUnmanagedInstrumentDescription } from 'entities/unmanaged_instrument'
import moment from 'moment'
import { ApiError, Form, useForm } from 'rac'
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useEffectSkipFirst } from 'utils/hooks/index'

type DialogFormContentProps = {
  instrumentForm: Form<InstrumentForm>
  error: ApiError
  instrument?: Instrument
  instrumentCategories?: InstrumentCategory[]
  laboratories?: Laboratory[]
  outsideLaboratories?: OutsideLaboratory[]
  currentDisabledOutsideLaboratory?: OutsideLaboratory | null
  onClickRemoveFile?: (a: InstrumentAttachment) => void
  onClickRemoveImage?: () => void
  formType: 'create' | 'update'
}

const maxInstrumentAlarmCount = 10

const DialogFormContent: React.FC<DialogFormContentProps> = (props) => {
  const { instrumentForm: form, error, instrument, instrumentCategories, laboratories, outsideLaboratories, currentDisabledOutsideLaboratory } = props

  const isOutsideLaboratoriesPresent = outsideLaboratories && outsideLaboratories.length > 0
  const [openEditDisabledOutsideLaboratory, setOpenEditDisabledOutsideLaboratory] = useState<boolean>(false)
  // const [selectedOwnerableType, setSelectedOwnerableType] = React.useState<InstrumentOwnerableType>(
  //   isLaboratoriesPresent ? 'Laboratory' : 'OutsideLaboratory',
  // )
  // const handleChangeOwnerableType = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedOwnerableType(event.target.value as OwnerableType)
  // }

  // useEffect(() => {
  //   form.newUpdateObject('ownerableType', selectedOwnerableType)
  // }, [selectedOwnerableType])

  const proofreadBaseDateEditable =
    instrument?.proofreadInfo?.proofreadBaseDateEditable === undefined ? true : instrument?.proofreadInfo?.proofreadBaseDateEditable

  useEffect(() => {
    // 校正のアラームの有効化が変更された際に_destroyの値を変更する
    form.update((f) => {
      const proofreadAlarmAttributes = f.proofreadAlarmAttributes
      if (proofreadAlarmAttributes) {
        if (f.alarmEnable) {
          proofreadAlarmAttributes._destroy = false
        } else {
          proofreadAlarmAttributes._destroy = true
        }
      }
    })
  }, [form.object.alarmEnable])

  /**
   * 選択中の顧客に担当者がいるかどうか
   */
  const isSelectedClientHasDealerUsers = useCallback(() => {
    let laboratory: Laboratory | OutsideLaboratory | undefined = instrument?.ownerable
    if (laboratory) {
      // すでに顧客設定済み（機器の編集）の場合
      return Boolean(laboratory.hasDealerUsers)
    } else {
      if (form.object.ownerableType === 'Laboratory') {
        // 提携顧客の場合
        laboratory = laboratories?.find((lab) => lab.id === form.object.ownerableId)
      } else {
        // 外部顧客の場合
        laboratory = outsideLaboratories?.find((lab) => lab.id === form.object.ownerableId)
      }
      return Boolean(laboratory?.hasDealerUsers)
    }
  }, [form.object.ownerableId, form.object.ownerableType, laboratories, outsideLaboratories])

  const handleClickEditDisabledOutsideLaboratory = () => {
    setOpenEditDisabledOutsideLaboratory(true)
    form.newUpdateObject('ownerableId', '')
  }

  const handleRemoveInstrumentAlarm = (alarmIndex: number) => {
    form.update((f) => {
      if (!f.instrumentAlarmsAttributes || !f.instrumentAlarmsAttributes[alarmIndex]) {
        return
      }
      if (props.formType === 'create') {
        f.instrumentAlarmsAttributes.splice(alarmIndex, 1)
      } else {
        f.instrumentAlarmsAttributes[alarmIndex]._destroy = true
      }
    })
  }

  const handleClickAddInstrumentAlarm = () => {
    const now = moment().toString()
    form.update((f) => {
      if (!f.instrumentAlarmsAttributes) {
        return
      }
      if (f.instrumentAlarmsAttributes.filter((c) => !c._destroy).length >= maxInstrumentAlarmCount) {
        alert(`機器アラームの設定は最大${maxInstrumentAlarmCount}個までです`)
        return
      }
      if (f.instrumentAlarmsAttributes) {
        f.instrumentAlarmsAttributes.push({ createdAt: now })
      } else {
        f.instrumentAlarmsAttributes = [{ createdAt: now }]
      }
    })
  }

  useEffectSkipFirst(() => {
    // モーダルを閉じる際に顧客のフォームを初期化しておく
    if (!openEditDisabledOutsideLaboratory) {
      form.update((f) => {
        delete f.ownerableId
      })
    }
  }, [openEditDisabledOutsideLaboratory])

  return (
    <DialogContent>
      <ImageInput
        attr={['imageAttributes', 'attachment']}
        form={form}
        urlAttr={['instrumentBasicInfoAttributes', 'imageUrl']}
        onClickRemove={props.onClickRemoveImage}
      />
      <Stack>
        <label style={{ marginBottom: 10 }}>顧客</label>
        <div>
          {/* 外部顧客で無効化の場合 */}
          {currentDisabledOutsideLaboratory ? (
            <>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                <div style={{ fontSize: 16, marginRight: 10 }}>{currentDisabledOutsideLaboratory.name}</div>
                <DeletedLabel style={{ marginRight: 10 }} />
                {isOutsideLaboratoriesPresent && !openEditDisabledOutsideLaboratory && (
                  <ThemedButton color="secondary" onClick={handleClickEditDisabledOutsideLaboratory}>
                    編集
                  </ThemedButton>
                )}
              </div>
              <Collapse in={openEditDisabledOutsideLaboratory}>
                <div style={{ flex: 1 }}>
                  {outsideLaboratories && (
                    <AutocompleteSelectField
                      form={form}
                      attr="ownerableId"
                      labelId="select-outside-laboratory"
                      data={outsideLaboratories.map((outsideLaboratory) => {
                        return { value: outsideLaboratory.id!, label: outsideLaboratory.name! }
                      })}
                      label="外部顧客"
                      apiError={error}
                      required
                    />
                  )}
                </div>
              </Collapse>
            </>
          ) : (
            <>
              {form.object.sharingState === 'sharing' ? (
                // 共有機器は顧客を変更できない
                <p style={{ marginBottom: 10 }}>{instrument?.ownerable?.name || '-'}</p>
              ) : (
                <>
                  {isOutsideLaboratoriesPresent ? (
                    <AutocompleteSelectField
                      form={form}
                      attr="ownerableId"
                      labelId="select-outside-laboratory"
                      data={outsideLaboratories.map((outsideLaboratory) => {
                        return { value: outsideLaboratory.id!, label: outsideLaboratory.name! }
                      })}
                      label="外部顧客"
                      apiError={error}
                      required
                    />
                  ) : (
                    <NoSelectableOutsideLaboratory />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Stack>
      <Stack>
        <label style={{ marginBottom: 10 }}>機器情報</label>
        <Flex>
          <StringField label="メーカー" attr={['instrumentBasicInfoAttributes', 'maker']} form={form} apiError={error} required />
          <StringField label="型番" attr={['instrumentBasicInfoAttributes', 'modelNumber']} form={form} apiError={error} required />
        </Flex>
        <StringField label="名称" attr={['instrumentBasicInfoAttributes', 'name']} form={form} apiError={error} required />
        {instrumentCategories && instrumentCategories.length > 0 && (
          <SelectField
            form={form}
            attr={['instrumentBasicInfoAttributes', 'instrumentCategoryId']}
            data={instrumentCategories.map((instrumentCategory) => {
              return { value: instrumentCategory.id!, label: instrumentCategory.name! }
            })}
            labelId="select-instrument-categories"
            label="機器カテゴリ"
            apiError={error}
            includeBlank
          />
        )}
        <Flex>
          <StringField label="シリアル番号" attr={['instrumentBasicInfoAttributes', 'serialNumber']} form={form} apiError={error} />
          <StringField label="管理番号" attr={['instrumentBasicInfoAttributes', 'manageNumber']} form={form} apiError={error} />
        </Flex>
        <StringField label="機器の説明等" attr={['instrumentBasicInfoAttributes', 'note']} form={form} apiError={error} multiline />
      </Stack>
      <Stack>
        <label style={{ marginBottom: 10 }}>外部リンク設定</label>
        <Flex>
          <StringField label="表示名" attr={['instrumentBasicInfoAttributes', 'externalLinkName']} form={form} apiError={error} />
          <StringField label="URL" attr={['instrumentBasicInfoAttributes', 'externalLink']} form={form} apiError={error} />
        </Flex>
      </Stack>
      <AttachmentsList
        fileAttachments={instrument?.instrumentBasicInfo?.fileAttachments || []}
        onClickRemove={props.onClickRemoveFile}
        emptyText=" "
      />
      <FilesInput form={form} attr="attachmentsAttributes" apiError={error} />
      <CheckboxAccordion
        label="機器のアラーム設定をする"
        onChangeChecked={(checked) => {
          form.newUpdateObject(['instrumentBasicInfoAttributes', 'requireInstrumentAlarm'], checked)
        }}
        defaultChecked={instrument?.instrumentBasicInfo?.requireInstrumentAlarm}
      >
        {form.object.instrumentAlarmsAttributes?.map((alarm: InstrumentAlarmAttributeForm, i: number) => {
          return alarm._destroy ? (
            <></>
          ) : (
            <div key={`instrumentAlarm-${i}`}>
              <div style={{ padding: 20, backgroundColor: '#fafafa', margin: '10px 0', position: 'relative' }}>
                <Flex style={{ marginBottom: 10 }}>
                  <label>アラーム情報{i + 1}</label>
                </Flex>
                <Flex>
                  <div>
                    <Flex alignItems="flex-start">
                      <Tooltip title={proofreadBaseDateEditable ? '' : cantEditProofreadInfoMessage}>
                        <div style={{ width: '40%' }}>
                          <DateField
                            label="基準日"
                            attr={['instrumentAlarmsAttributes', i, 'baseDate']}
                            form={form}
                            apiError={error}
                            required
                            clearable
                          />
                        </div>
                      </Tooltip>
                      <div style={{ marginTop: 12 }}>の</div>

                      <NumberField attr={['instrumentAlarmsAttributes', i, 'value']} form={form} required apiError={error} width={160} />
                      <SelectField
                        form={form}
                        labelId="proofread-alarm-unit-label"
                        attr={['instrumentAlarmsAttributes', i, 'unit']}
                        label="単位"
                        data={[
                          { value: 'day', label: '日' },
                          { value: 'week', label: '週' },
                          { value: 'month', label: '月' },
                        ]}
                        required
                        apiError={error}
                        width={100}
                      />
                      <div style={{ marginTop: 12, width: 150 }}>前に通知</div>
                    </Flex>
                    <Flex>
                      <StringField
                        label="アラームのメッセージ"
                        attr={['instrumentAlarmsAttributes', i, 'alarmMessage']}
                        form={form}
                        required
                        apiError={error}
                        multiline
                      />
                    </Flex>
                  </div>
                </Flex>
                <div style={{ minWidth: 30, top: 20, right: 20, position: 'absolute' }}>
                  {i > 0 && <CancelIcon color="action" onClick={() => handleRemoveInstrumentAlarm(i)}></CancelIcon>}
                </div>
              </div>
            </div>
          )
        })}
        {form.object.instrumentAlarmsAttributes &&
          form.object.instrumentAlarmsAttributes.filter((c) => !c._destroy).length < maxInstrumentAlarmCount && (
            <ThemedButton onClick={() => handleClickAddInstrumentAlarm()} color="secondary">
              アラームを追加
            </ThemedButton>
          )}
      </CheckboxAccordion>
      <CheckboxAccordion
        label="機器の校正管理をする"
        onChangeChecked={(checked) => {
          form.newUpdateObject('requireProofread', checked)
        }}
        defaultChecked={form.object.requireProofread}
      >
        <div style={{ marginBottom: 10 }}>
          <label>校正情報</label>
        </div>
        <Flex>
          <Tooltip title={proofreadBaseDateEditable ? '' : cantEditProofreadInfoMessage}>
            <div style={{ width: '100%' }}>
              <NumberField
                label="校正周期"
                attr={['proofreadInfoAttributes', 'cycle']}
                form={form}
                apiError={error}
                required
                disabled={!proofreadBaseDateEditable}
              />
            </div>
          </Tooltip>
          <Tooltip title={proofreadBaseDateEditable ? '' : cantEditProofreadInfoMessage}>
            <div style={{ width: '100%' }}>
              <SelectField
                form={form}
                labelId="proofread-unit-label"
                attr={['proofreadInfoAttributes', 'cycleUnit']}
                label="周期単位"
                data={[
                  { value: 'month', label: '月毎' },
                  { value: 'year', label: '年毎' },
                ]}
                apiError={error}
                required
                disabled={!proofreadBaseDateEditable}
              />
            </div>
          </Tooltip>
        </Flex>
        <Flex>
          <Tooltip title={proofreadBaseDateEditable ? '' : cantEditProofreadInfoMessage}>
            <div style={{ width: '100%' }}>
              <MonthField
                label="校正基準月・最終校正月"
                attr={['proofreadInfoAttributes', 'baseDate']}
                form={form}
                apiError={error}
                required
                disabled={!proofreadBaseDateEditable}
              />
            </div>
          </Tooltip>
        </Flex>
        <Flex>
          <NumberField label="校正価格" attr={['proofreadInfoAttributes', 'price']} form={form} apiError={error} />
        </Flex>
        <ProofreadAlarmForm
          {...{
            form,
            error,
            ownerableType: 'dealer',
            isSelectedClientHasDealerUsers,
          }}
        />
      </CheckboxAccordion>
    </DialogContent>
  )
}

type NewInstrumentFormDialogProps = {
  onComplete: (instrument: Instrument) => void
  instrumentCategories?: InstrumentCategory[]
  outsideLaboratories?: OutsideLaboratory[]
  laboratories?: Laboratory[]
}

export const NewInstrumentFormDialog: React.FC<NewInstrumentFormDialogProps> = ({
  onComplete,
  instrumentCategories,
  outsideLaboratories,
  laboratories,
}: NewInstrumentFormDialogProps) => {
  const [open, setOpen] = useState(false)
  const instrumentForm = useForm<InstrumentForm>(
    {
      ownerableType: 'OutsideLaboratory',
      instrumentBasicInfoAttributes: {},
      reservationTermAttributes: { section: 5 },
      proofreadInfoAttributes: {},
      instrumentAlarmsAttributes: [{}],
      imageAttributes: {},
      attachmentsAttributes: [{}],
      proofreadAlarmAttributes: {},
    },
    'instrument',
  )
  const api = usePartnerPostInstrumentApi()
  const globalState = useContext(GlobalStateContext)

  useEffectSkipFirst(() => {
    setOpen(false)
    onComplete(api.response.instrument)
    instrumentForm.resetForm()
  }, [api.response.instrument])

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    api.execute(instrumentForm)
  }

  return (
    <div>
      <ThemedButton color="primary" onClick={handleClickOpen}>
        新規登録
      </ThemedButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xl">
        <DialogTitle id="form-dialog-title">機器登録</DialogTitle>
        <DialogFormContent
          {...{
            instrumentForm,
            error: api.apiError,
            instrumentCategories,
            outsideLaboratories,
            laboratories,
            formType: 'create',
          }}
        />
        <DialogActions>
          <ThemedButton onClick={handleClose} color="secondary">
            閉じる
          </ThemedButton>
          <ThemedButton onClick={handleSubmit} color="success">
            登録
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

type EditInstrumentFormDialogProps = {
  open: boolean
  instrument: Instrument
  onComplete: (instrument: Instrument) => void
  onCompleteExcludeManagement?: (instrument: Instrument) => void
  onCompleteDiscard?: () => void
  onCompleteRestoreManagement?: () => void
  onCompleteRemoveImage?: () => void
  onCancel: () => void
  instrumentCategories?: InstrumentCategory[]
  outsideLaboratories?: OutsideLaboratory[]
  currentDisabledOutsideLaboratory?: OutsideLaboratory
}
export const EditInstrumentFormDialog: React.FC<EditInstrumentFormDialogProps> = ({
  open,
  instrument,
  onComplete,
  onCompleteExcludeManagement,
  onCompleteDiscard,
  onCompleteRestoreManagement,
  onCompleteRemoveImage,
  onCancel,
  instrumentCategories,
  outsideLaboratories,
}: EditInstrumentFormDialogProps) => {
  const propsToInstrumentForm = (): InstrumentForm => {
    const { instrumentBasicInfo, proofreadInfo, proofreadAlarm } = instrument
    const alarms = instrument.instrumentAlarms && instrument.instrumentAlarms.length > 0 ? instrument.instrumentAlarms : [{}]

    return Object.assign(
      {},
      {
        ...instrument,
        instrumentBasicInfoAttributes: instrumentBasicInfo,
        proofreadInfoAttributes: { ...proofreadInfo },
        alarmEnable: !!proofreadAlarm,
        proofreadAlarmAttributes: { ...proofreadAlarm },
        instrumentAlarmsAttributes: alarms,
        imageAttributes: {},
        attachmentsAttributes: [{}],
      },
    )
  }

  const instrumentForm = useForm<InstrumentForm>(propsToInstrumentForm(), 'instrument')
  const api = usePartnerPatchInstrumentApi()
  const [currentDisabledOutsideLaboratory, setCurrentDisabledOutsideLaboratory] = React.useState<OutsideLaboratory | null>(null)
  const deleteApi = usePartnerDeleteInstrumentApi()
  const deleteUnmanagedInstrumentApi = usePartnerDeleteUnmanagedInstrumentApi()
  const restoreManagementInstrumentApi = usePartnerRestoreManagementUnmanagedInstrumentApi()
  const deleteImageApi = useDeleteInstrumentImageApi('dealer_user')
  const globalState = useContext(GlobalStateContext)
  const deleteFileApi = useDeleteAttachmentsApi('dealer_user', 'instruments')
  const [targetFileId, setTargetFileId] = useState<number>()

  const handleClickExcludeManagement = () => {
    deleteApi.execute(instrument.id!)
  }

  const handleClickDiscard = () => {
    globalState.confirm(warningDiscardUnmanagedInstrumentDescription, (event) => {
      if (event === 'ok') {
        deleteUnmanagedInstrumentApi.execute(instrument.id!)
      }
    })
  }

  const handleClickRestoreManagement = () => {
    restoreManagementInstrumentApi.execute(instrument.id!)
  }

  const handleClickRemoveImage = () => {
    globalState.confirm('機器画像を削除しますか？　この操作は取り消せません', (event) => {
      if (event === 'ok') {
        deleteImageApi.execute(instrumentForm.object.id!)
      }
    })
  }

  const handleClickRemoveFile = (a: InstrumentAttachment) => {
    setTargetFileId(a.id)
    globalState.confirm(`添付ファイル ${a.name} を削除しますか？ この操作は取り消せません`, (event) => {
      if (event === 'ok') {
        deleteFileApi.execute(instrumentForm.object.id!, a.id!)
      }
    })
  }

  // 廃棄時
  useEffectSkipFirst(() => {
    if (deleteUnmanagedInstrumentApi.isSuccess() && onCompleteDiscard) {
      onCompleteDiscard()
    }
  }, [deleteUnmanagedInstrumentApi.loading])

  useEffectSkipFirst(() => {
    if (restoreManagementInstrumentApi.isSuccess() && onCompleteRestoreManagement) {
      onCompleteRestoreManagement()
    }
  }, [restoreManagementInstrumentApi.loading])

  useEffect(() => {
    instrumentForm.set(() => propsToInstrumentForm())
  }, [instrument])

  useEffectSkipFirst(() => {
    onComplete(api.response.instrument)
  }, [api.response.instrument])

  useEffectSkipFirst(() => {
    if (deleteApi.isSuccess() && onCompleteExcludeManagement) {
      onCompleteExcludeManagement(deleteApi.response.instrument)
    }
  }, [deleteApi.loading])

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteImageApi.loading)
    if (deleteImageApi.isSuccess()) {
      instrumentForm.newUpdateObject(['instrumentBasicInfoAttributes', 'imageUrl'], '')
      onCompleteRemoveImage && onCompleteRemoveImage()
    }
  }, [deleteImageApi.loading])

  useEffectSkipFirst(() => {
    globalState.setLoading(deleteFileApi.loading)
    if (deleteFileApi.isSuccess()) {
      const arr = instrumentForm.getValue(['instrumentBasicInfoAttributes', 'fileAttachments']) as FileAttachment[]
      const i = arr.findIndex((a) => a.id === targetFileId)
      arr.splice(i, 1)
      instrumentForm.newUpdateObject(['instrumentBasicInfoAttributes', 'fileAttachments'], arr)
    }
  }, [deleteFileApi.loading])

  const handleSubmit = () => {
    if (!instrumentForm.object.requireProofread) {
      // 校正管理にチェックがなければ、校正関連の入力内容を破棄
      api.execute({ ...instrumentForm.object, proofreadInfoAttributes: {} })
    } else {
      api.execute(instrumentForm.object)
    }
  }

  useEffect(() => {
    if (open) {
      if (instrument.ownerableId && instrument.ownerableType === 'OutsideLaboratory') {
        const currentOutsideLaboratory = instrument.ownerable as OutsideLaboratory
        if (currentOutsideLaboratory && currentOutsideLaboratory.state === 'disable') {
          setCurrentDisabledOutsideLaboratory(currentOutsideLaboratory)
        } else {
          setCurrentDisabledOutsideLaboratory(null)
        }
      }
    }
  }, [open])

  return (
    <div>
      <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">機器編集</DialogTitle>
        <DialogFormContent
          {...{
            instrumentForm,
            error: api.apiError,
            instrument,
            instrumentCategories,
            outsideLaboratories,
            currentDisabledOutsideLaboratory,
            onClickRemoveImage: handleClickRemoveImage,
            onClickRemoveFile: handleClickRemoveFile,
            formType: 'update',
          }}
        />
        <DialogActions>
          <Flex justifyContent="space-between" style={{ width: '100%' }}>
            {instrument.aasmState === 'managed' ? (
              <div>
                <ThemedButton color="error" variant="text" onClick={handleClickExcludeManagement}>
                  管理対象外にする
                </ThemedButton>
              </div>
            ) : (
              <Flex>
                <ThemedButton color="error" variant="text" onClick={handleClickDiscard} style={{ marginRight: 10 }}>
                  廃棄する
                </ThemedButton>
                {currentDisabledOutsideLaboratory ? (
                  <DisabledRestoreManagementButton />
                ) : (
                  <ThemedButton color="success" onClick={handleClickRestoreManagement}>
                    もとに戻す
                  </ThemedButton>
                )}
              </Flex>
            )}
            <Flex>
              <ThemedButton onClick={onCancel} color="secondary">
                閉じる
              </ThemedButton>
              <ThemedButton onClick={handleSubmit} color="success">
                更新
              </ThemedButton>
            </Flex>
          </Flex>
        </DialogActions>
      </Dialog>
    </div>
  )
}

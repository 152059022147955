import { BaseEntity } from 'entities'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = ['id', 'dealerId', 'name', 'state'] as const

type OriginalKeyTypeMap = BaseEntity & {
  dealerId?: number
  name?: string
  state?: 'disable' | 'enable'
}

// モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K]
}

export type OutsideLaboratory = OriginalProperties & {
  // リレーションやロジックのために付随する属性
  hasDealerUsers?: boolean
}

const formKeys = [] as const

type FormKeyTypeMap = {}

type FormProperties = {
  [K in typeof formKeys[number]]?: FormKeyTypeMap[K];
}

export type OutsideLaboratoryForm = OriginalProperties & FormProperties

// 送信するフォームのキー。ここにないものは送信されない
export const OutsideLaboratoryFormKeys: (keyof OutsideLaboratoryForm)[] = [...orginalKeys, ...formKeys]

export type OutsideLaboratorySearchForm = {
  nameCont?: string
}

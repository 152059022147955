import { BaseEntity, ID } from 'entities'
import { AttachmentAttributes } from 'entities/attachment_attributes'
import { DealerUser } from 'entities/dealer_user'
import { DefectReportAttachment } from 'entities/defect_report_attachment'
import { ExAttribute } from 'entities/ex_attribute'
import { Instrument } from 'entities/instrument'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEstimate, RepairEstimateForm } from 'entities/repair_estimate'
import { RepairEvent } from 'entities/repair_event'
import { User } from 'entities/user'
import { SearchQuery } from 'rac'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = ['id', 'reporterableId', 'reporterableType', 'instrumentId', 'repairInfoId', 'proofreadEventId', 'point', 'content', 'reportedAt', 'reportedUserName'] as const

type OriginalKeyTypeMap = BaseEntity & {
  reporterableId?: ID
  reporterableType?: 'User' | 'DealerUser'
  instrumentId?: ID
  repairInfoId?: ID
  proofreadEventId?: ID
  point?: string
  content?: string
  reportedAt?: string
  reportedUserName?: string
}

// モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K];
}

/**
 * 不具合報告
 */
export type DefectReport = OriginalProperties & {
  // リレーションやロジックのために付随する属性
  tableExAttrs?: ExAttribute[]
  fileAttachments?: DefectReportAttachment[]
  instrument?: Instrument
  repairEvent?: RepairEvent
  repairEstimate?: RepairEstimate
  proofreadEvent?: ProofreadEvent
  sharedReport?: boolean
  reporterable?: User | DealerUser
}

const formKeys = ['attachmentsAttributes', 'repairEstimateAttributes',] as const

type FormKeyTypeMap = {
  attachmentsAttributes?: AttachmentAttributes[]
  repairEstimateAttributes?: RepairEstimateForm
}

type FormProperties = {
  [K in typeof formKeys[number]]?: FormKeyTypeMap[K];
}

/**
 * フォームで用いる属性。定義していない属性は型エラーで弾く
 */
export type DefectReportForm = OriginalProperties & FormProperties

/**
 * 送信するフォームのキー。ここにないものは送信されない
 */
export const DefectReportFormKeys: (keyof DefectReportForm)[] = [...orginalKeys, ...formKeys]

export type DefectReportSearchForm = {
  searchText?: string
}

export const getDefectReportSearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: [
    'point',
    'content',

    // FIXME: multiDBの不具合暫定対応
    'reportedUserName', // reported_user_nameカラムで検索する
    // ['reporterable', { attrs: ['familyName', 'givenName'], polymorphicType: 'User' }],
    // ['reporterable', { attrs: ['name'], polymorphicType: 'DealerUser' }],
  ],
})

export type ReportWithEstimateForm = {
  defectReport?: DefectReportForm
  repairEstimate?: RepairEstimateForm
  proofreadEventId?: number
}

import { BaseEntity } from 'entities'
import { AttachmentAttributes } from 'entities/attachment_attributes'

export type CommentableType = 'User' | 'Dealer'

export type InquiryComment = BaseEntity & {
  inquiryId?: number
  commentableType?: CommentableType
  senderName?: string
  body?: string
  icon?: string
  attachment?: InquiryAttachment
}

export type InquiryAttachment = {
  name?: string
  url?: string
  type?: string
}

export type InquiryCommentForm = InquiryComment & {
  attachmentAttributes?: AttachmentAttributes // 現状の仕様はコメント一つに対してファイル1つを貼付可能
}

export const inquiryCommentValidation = {
  body: { required: 'お問合せ内容が未入力です' },
}

export const getInquiryCommentValidationConditions = (isBlankAttachment: boolean) => {
  return {
    body: isBlankAttachment,
  }
}

export const maxFileSize = 5242880

// TODO: 編集フォームを実装する際は entities/instrument.ts のコメントと実装を参考に、同様の構造で実装する

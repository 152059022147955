import { BaseEntity } from 'entities'

export type ReservationTimeConstraint = BaseEntity & {
  startTime?: string
  endTime?: string
  price?: number
}

export type ReservationTimeConstraintForm = ReservationTimeConstraint & {
  _destroy?: boolean
}

export const defaultTime = { startTime: '2000-01-01 00:00', endTime: '2000-01-01 00:00' }

// TODO: 編集フォームを実装する際は entities/instrument.ts のコメントと実装を参考に、同様の構造で実装する

import { AxiosHttpClient } from 'api/axios'
import { ProofreadScheduleResponse } from 'api/mypage/proofread_plans/proofread_schedule'
import { useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ProofreadScheduleForm, ProofreadScheduleFormKeys } from 'entities/proofread_schedule'
import { pick } from 'lodash'
import { Form, ApiSet } from 'rac'

/**
 * 登録
 */
export const usePartnerPostProofreadSchedlueApi = (
  form: Form<ProofreadScheduleForm>,
): ApiSet<ProofreadScheduleResponse> & { execute: () => void } => {
  const api = useRepruaPostApi<ProofreadScheduleResponse, ProofreadScheduleForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadSchedule: {} },
  })

  const execute = () => {
    const path = 'proofread_schedules/'
    api.execute(path, form)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export const usePartnerPatchProofreadScheduleApi = (
  form: Form<ProofreadScheduleForm>,
): ApiSet<ProofreadScheduleResponse> & { execute: () => void } => {
  const api = useRepruaPatchApi<ProofreadScheduleResponse, ProofreadScheduleForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { proofreadSchedule: {} },
  })

  const execute = () => {
    const path = `proofread_schedules/${form.object.id}`
    api.execute(path, pick(form.object, ProofreadScheduleFormKeys))
  }

  return { ...api, execute }
}

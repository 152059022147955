import { AxiosHttpClient } from 'api/axios'
import { useRepruaDownloadApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi, useRepruaShowApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { getRepairEstimateSearchQuery, RepairEstimate, RepairEstimateForm, RepairEstimateFormKeys, RepairEstimateSearchForm } from 'entities/repair_estimate'
import { pick } from 'lodash'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

type RepairEstimatesResponse = PagingResponse & {
  repairEstimates: RepairEstimate[]
}
/**
 * 一覧
 */
export type FetchRepairEstimateApiSet = IndexApiSet<RepairEstimatesResponse> & {
  execute: () => void
  executeAndPageReset: () => void
}
export function useFetchRepairEstimatesApi(searchForm: Form<RepairEstimateSearchForm>): FetchRepairEstimateApiSet {
  const api = useRepruaIndexApi<RepairEstimatesResponse, RepairEstimateSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { repairEstimates: [], totalCount: 0 },
  })
  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'repair_estimates'
    api.execute(path, { params: rest, searchQuery: getRepairEstimateSearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute, executeAndPageReset }
}

type RepairEstimateResponse = BaseResponse & {
  repairEstimate: RepairEstimate
}
/**
 * 取得
 */
export function useFetchRepairEstimateApi(
  id: number,
): ApiSet<RepairEstimateResponse> & {
  execute: () => void
} {
  const api = useRepruaShowApi<RepairEstimateResponse, RepairEstimateForm>(new AxiosHttpClient('user'), {
    initialResponse: { repairEstimate: {} },
  })

  const execute = () => {
    const path = `repair_estimates/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}
/**
 * 作成
 */
export function usePostRepairEstimateApi(
  repairEstimateForm: Form<RepairEstimateForm>,
): ApiSet<RepairEstimateResponse> & {
  execute: () => void
} {
  const api = useRepruaPostApi<RepairEstimateResponse, RepairEstimateForm>(new AxiosHttpClient('user'), {
    initialResponse: { repairEstimate: {} },
  })

  const execute = () => {
    const path = 'repair_estimates'
    api.execute(path, repairEstimateForm)
  }

  return { ...api, execute }
}
/**
 * 編集
 */
export function usePatchRepairEstimateApi(): ApiSet<RepairEstimateResponse> & {
  execute: (repairEstimateForm: RepairEstimateForm) => void
} {
  const api = useRepruaPatchApi<RepairEstimateResponse, RepairEstimateForm>(new AxiosHttpClient('user'), {
    initialResponse: { repairEstimate: {} },
  })

  const execute = (repairEstimateForm: RepairEstimateForm) => {
    const path = `repair_estimates/${repairEstimateForm.id}`
    api.execute(path, pick(repairEstimateForm, RepairEstimateFormKeys))
  }

  return { ...api, execute }
}

/**
 * 出力
 */
export const useExportRepairEstimatesApi = (searchForm: Form<RepairEstimateSearchForm>): { execute: (format: 'xlsx') => void } => {
  const api = useRepruaDownloadApi<RepairEstimateSearchForm>(new AxiosHttpClient('user'))

  const { searchText, ...rest } = searchForm.object

  const execute = (format: 'xlsx') => {
    const path = `repair_estimates.${format}`
    api.execute(path, { params: rest, searchQuery: getRepairEstimateSearchQuery(searchText) })
  }

  return { execute }
}

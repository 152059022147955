import { BaseEntity } from '.'

/**
 * ディーラーユーザー権限
 */
export type DealerUserAuthority = BaseEntity & {
  dealerUserId?: number
  privileged?: boolean
}

export type DealerUserAuthorityForm = DealerUserAuthority

export type BulkUpdateDealerUserAuthorityForm = {
  dealerUserAuthorities: DealerUserAuthorityForm[]
}

export const authorityData = {
  privileged: '管理者',
}

export const initialFormState = {
  dealerUserAuthorities: [],
}

export const authorityKeys = Object.keys(authorityData)
export type AuthorityTypes = 'privileged'

// TODO: 編集フォームを実装する際は entities/instrument.ts のコメントと実装を参考に、同様の構造で実装する

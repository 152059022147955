import { Tooltip } from '@mui/material'
import { usePartnerPatchDefectReportApi, usePartnerDeleteDefectReportApi, usePartnerPostDefectReportApi } from 'api/partner/defect_report'
import AttachmentsList from 'components/shared/data/attachments_list'
import DataLabel from 'components/shared/data/data_label'
import { Flex } from 'components/shared/flex'
import { StringField, DateTimeField, FilesInput, NumberField, SelectField } from 'components/shared/form/input'
import Modal, { ModalSet, ModalState } from 'components/shared/modal'
import { ModalStep, StepperModal } from 'components/shared/stepper_modal'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { PartnerStateContext } from 'contexts/partner_state_context'
import { ID } from 'entities'
import { DefectReportForm, DefectReport } from 'entities/defect_report'
import { FileAttachment } from 'entities/file_attachment'
import { ProofreadEvent } from 'entities/proofread_event'
import { getDeliveryTermSelectData } from 'entities/repair_estimate'
import { Form, ApiError, useForm } from 'rac'
import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useEffectSkipFirst } from 'utils/hooks'

const dialogContent = (defectReportForm: Form<DefectReportForm>, error: ApiError, fileAttachments?: FileAttachment[]) => {
  return (
    <>
      <Flex>
        <StringField label="報告者" attr="reportedUserName" form={defectReportForm} apiError={error} />
        <DateTimeField label="発生日時" attr="reportedAt" form={defectReportForm} apiError={error} required />
      </Flex>

      <Flex>
        <StringField label="不具合箇所" attr="point" form={defectReportForm} apiError={error} required />
      </Flex>

      <Flex>
        <StringField label="不具合内容" attr="content" form={defectReportForm} apiError={error} multiline required />
      </Flex>

      <AttachmentsList fileAttachments={fileAttachments || []} emptyText=" " />
      <FilesInput form={defectReportForm} attr="attachmentsAttributes" keyword="defectReport" />
    </>
  )
}

type NewDefectReportFormDialogProps = {
  modalState: ModalState
  onComplete?: () => void
  instrumentId?: ID
  proofreadEventId?: ID
}

export const NewDefectReportFormDialog: React.FC<NewDefectReportFormDialogProps> = (props: NewDefectReportFormDialogProps) => {
  const defectReportForm = useForm<DefectReportForm>({}, 'defectReport')
  const api = usePartnerPostDefectReportApi()
  const globalState = useContext(GlobalStateContext)
  const { currentDealerUser } = useContext(PartnerStateContext)

  useEffectSkipFirst(() => {
    props.modalState.setOpen(false)
    if (props.onComplete) props.onComplete()
  }, [api.response.defectReport])

  useEffect(() => {
    defectReportForm.update((f) => {
      if (props.instrumentId) {
        f.instrumentId = props.instrumentId
      }
      if (props.proofreadEventId) {
        f.proofreadEventId = props.proofreadEventId
      }
      f.reportedUserName = currentDealerUser.name
    })
  }, [props.modalState.open])

  useEffect(() => {
    globalState.setLoading(api.loading)
  }, [api.loading])

  const handleClose = () => {
    props.modalState.setOpen(false)
  }

  const handleSubmit = () => {
    api.execute(defectReportForm)
  }

  return (
    <div>
      <Modal
        modalId="new-defect-report"
        modalState={props.modalState}
        title="不具合報告の登録"
        size="sm"
        footer={
          <>
            <ThemedButton onClick={handleClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              登録
            </ThemedButton>
          </>
        }
      >
        {dialogContent(defectReportForm, api.apiError)}
      </Modal>
    </div>
  )
}

type EditDefectReportFormDialogProps = {
  modalState: ModalState
  defectReport: DefectReport
  onComplete: (defectReport: DefectReport) => void
  onDelete: (defectReport: DefectReport) => void
  onClose: () => void
}

export const EditDefectReportFormDialog: React.FC<EditDefectReportFormDialogProps> = (props: EditDefectReportFormDialogProps) => {
  const { currentDealerUser } = useContext(PartnerStateContext)
  const { defectReport, onComplete, onClose } = props
  const api = usePartnerPatchDefectReportApi()
  const deleteApi = usePartnerDeleteDefectReportApi()
  const globalState = useContext(GlobalStateContext)

  const defectReportForm = useForm<DefectReportForm>(defectReport, 'defectReport')

  useEffectSkipFirst(() => {
    if (!(api.apiError.messages.length > 0)) {
      onClose()
      onComplete(api.response.defectReport)
    }
  }, [api.response.defectReport])

  useEffect(() => {
    defectReportForm.set(defectReport)
  }, [defectReport])

  const handleSubmit = () => {
    api.execute(defectReportForm.object)
  }

  const handleClickDelete = (defectReport: DefectReport) => {
    globalState.confirm('本当に削除しますか？', (event) => {
      if (event === 'ok') {
        deleteApi.execute(defectReport.id!)
      }
    })
  }

  useEffect(() => {
    if (deleteApi.isSuccess()) {
      props.onDelete(api.response.defectReport)
    }
  }, [deleteApi.loading])

  const canDelete = useMemo(() => {
    return !(defectReport.reporterableType === 'DealerUser' && defectReport.reporterableId === currentDealerUser.id)
  }, [defectReport, currentDealerUser])

  return (
    <Modal
      modalState={props.modalState}
      modalId="edit-defect-report"
      title="不具合報告編集"
      size="sm"
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
          <Tooltip title={canDelete ? '作成者以外削除出来ません' : ''}>
            <div>
              <ThemedButton
                color={canDelete ? undefined : 'error'}
                onClick={() => handleClickDelete(props.defectReport)}
                disabled={canDelete}
              >
                削除
              </ThemedButton>
            </div>
          </Tooltip>
          <Flex>
            <ThemedButton onClick={onClose} color="secondary">
              閉じる
            </ThemedButton>
            <ThemedButton onClick={handleSubmit} color="success">
              更新
            </ThemedButton>
          </Flex>
        </div>
      }
    >
      {dialogContent(defectReportForm, api.apiError, defectReport.fileAttachments)}
    </Modal>
  )
}

type CreateDefectReportWithEstimateFormDialogProps = {
  modalSet: ModalSet<ProofreadEvent>
  onComplete: () => void
}

export const CreateDefectReportWithEstimateFormDialog: React.FC<CreateDefectReportWithEstimateFormDialogProps> = (
  props: CreateDefectReportWithEstimateFormDialogProps,
) => {
  const { modalSet, onComplete } = props
  const { currentDealerUser } = useContext(PartnerStateContext)
  const postForm = useForm<DefectReportForm>(
    {
      instrumentId: modalSet.item.instrumentId,
      proofreadEventId: modalSet.item.id!,
      repairEstimateAttributes: {
        suppliableType: 'Dealer',
        suppliableId: currentDealerUser.dealerId,
      },
    },
    'defectReport',
  )
  const postApi = usePartnerPostDefectReportApi()
  const [stepperIndex, setStepperIndex] = useState(0)

  useEffectSkipFirst(() => {
    postForm.resetForm()
  }, [modalSet.item.id])

  const goNextStep = () => {
    setStepperIndex((prevIndex) => prevIndex + 1)
  }
  const goPrevStep = () => {
    setStepperIndex((prevIndex) => prevIndex - 1)
  }

  const handleSubmitDefectReport = () => {
    goNextStep()
  }
  const handleSubmitRepairEstimate = () => {
    postApi.execute(postForm)
  }

  useEffectSkipFirst(() => {
    if (!postApi.isSuccess()) {
      // 不具合報告の入力エラーの場合、1ページ目に戻す
      if (Object.keys(postApi.apiError.details).includes('defectReport')) {
        setStepperIndex(0)
      }
      return
    }
    onComplete()
  }, [postApi.loading])

  const steps: ModalStep[] = [
    {
      label: '不具合報告の作成',
      body: <div style={{ paddingTop: 12 }}>{dialogContent(postForm, postApi.apiError)}</div>,
      onClickNext: handleSubmitDefectReport,
    },
    {
      label: '修理見積の作成',
      body: (
        <div style={{ paddingTop: 12 }}>
          <EstimateForm form={postForm} apiError={postApi.apiError} />
        </div>
      ),
      allowBack: true,
      onClickBack: goPrevStep,
      onClickNext: handleSubmitRepairEstimate,
    },
  ]

  return (
    <StepperModal
      steps={steps}
      modalState={modalSet.modalState}
      size="sm"
      title="修理見積の提示"
      changeIndexManually
      index={stepperIndex}
    />
  )
}

type EstimateFormProps = {
  form: Form<DefectReportForm>
  apiError: ApiError
}
const EstimateForm: React.FC<EstimateFormProps> = (props) => {
  const { form, apiError } = props
  const inputProps = {
    form: form,
    apiError: apiError,
  }
  const getTotalText = () => `¥${(form.object.repairEstimateAttributes?.total || 0).toLocaleString()}`

  const taxRate = 0.1
  useEffect(() => {
    const newTax = Math.ceil((form.object.repairEstimateAttributes?.subtotal || 0) * taxRate)
    const newTotal = (form.object.repairEstimateAttributes?.subtotal || 0) + newTax
    form.update((f) => {
      if (f.repairEstimateAttributes) {
        f.repairEstimateAttributes.tax = newTax
        f.repairEstimateAttributes.total = newTotal
      }
    })
  }, [form.object.repairEstimateAttributes?.subtotal])
  useEffect(() => {
    const newTotal = (form.object.repairEstimateAttributes?.subtotal || 0) + (form.object.repairEstimateAttributes?.tax || 0)
    form.update((f) => {
      if (f.repairEstimateAttributes) {
        f.repairEstimateAttributes.total = newTotal
      }
    })
  }, [form.object.repairEstimateAttributes?.tax])
  return (
    <>
      <Flex justifyContent="space-between">
        <NumberField
          label="修理費用（税別）"
          attr={['repairEstimateAttributes', 'subtotal']}
          required
          {...inputProps}
          style={{ paddingRight: 20 }}
        />
        <NumberField label="消費税" attr={['repairEstimateAttributes', 'tax']} required {...inputProps} style={{ paddingRight: 20 }} />
        <DataLabel label="合計額" value={getTotalText()} />
      </Flex>
      <Flex>
        <SelectField
          label="納期"
          labelId="delivery-label"
          attr={['repairEstimateAttributes', 'deliveryTerm']}
          data={getDeliveryTermSelectData()}
          required
          {...inputProps}
          style={{ width: '50%' }}
        />
      </Flex>
      <Flex>
        <StringField label="引き渡し・修理作業の進め方・備考等" attr={['repairEstimateAttributes', 'note']} multiline {...inputProps} />
      </Flex>
      <FilesInput attr={['repairEstimateAttributes', 'attachmentsAttributes']} keyword="repairEstimate" {...inputProps} />
    </>
  )
}

import LinkIcon from '@mui/icons-material/Link'
import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, Link } from '@mui/material'
import { DeletedLabel } from 'components/partner/instrument/deleted_label'
import { Flex } from 'components/shared/flex'
import { LinkedInstrumentName } from 'components/shared/linked_instrument_name'
import StatusLabel from 'components/shared/status_label'
import { currentStateTexts, currentStateColors, Instrument, isOwnerableOutsideLaboratory } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import moment, { Moment } from 'moment'
import React, { ReactNode } from 'react'
import { ViewModel } from 'view_models'
import { ReservationTermViewModel } from 'view_models/reservation_term'

const MSEC_OF_DAY = 86400000

export class InstrumentViewModel extends ViewModel<Instrument> {
  // 登録日はInstrumentBasicInfoの作成日を参照する
  public registeredAt = () => {
    return moment(this.object.instrumentBasicInfo?.createdAt).format('Y/MM/DD')
  }

  public getImageUrl = () => {
    return this.object.instrumentBasicInfo?.imageUrl
  }

  public modelNumber(): string {
    return this.object.instrumentBasicInfo?.modelNumber || '指定なし'
  }

  public serialNumber(): string {
    return this.object.instrumentBasicInfo?.serialNumber || '指定なし'
  }

  public manageNumber(): string {
    return this.object.instrumentBasicInfo?.manageNumber || '指定なし'
  }

  public purchasedAt(): Moment | null {
    return this.object.instrumentBasicInfo?.purchasedAt ? moment(this.object.instrumentBasicInfo?.purchasedAt) : null
  }

  public purchasedAtText(): string {
    const date = this.purchasedAt()
    if (date) {
      return date.format('Y/MM/DD')
    } else {
      return '-'
    }
  }

  public purchasedPriceText(): string {
    if (this.object.instrumentBasicInfo?.purchasedPrice) {
      return `¥${this.object.instrumentBasicInfo?.purchasedPrice.toLocaleString()}`
    } else {
      return '-'
    }
  }

  /**
   * 型番・シリアル番号・管理番号をつなげて出力
   */
  public getInstrumentNumbers(): string {
    return `${this.object.instrumentBasicInfo?.modelNumber} / ${this.serialNumber()} / ${this.manageNumber()}`
  }

  public getReservationTermVM(): ReservationTermViewModel | null {
    if (this.object.reservationTerm) {
      return new ReservationTermViewModel(this.object.reservationTerm)
    } else {
      return null
    }
  }

  public fixDeadline = (deadline: number): string => {
    if (deadline >= 24) {
      return `${deadline / 24} 日`
    } else {
      return `${deadline} 時間`
    }
  }

  public getCancelPolicy(): string {
    const cancelPolicy = this.object.reservationTerm?.cancelPolicy
    if (cancelPolicy) {
      return `${this.fixDeadline(cancelPolicy.deadline)}前までキャンセル可能`
    } else {
      return '-'
    }
  }

  public getNote(): string {
    if (this.object.instrumentBasicInfo?.note) {
      return this.object.instrumentBasicInfo?.note
    } else {
      return '-'
    }
  }

  public currentStateView(): ReactNode {
    return <StatusLabel text={currentStateTexts[this.object.currentState!]} color={currentStateColors[this.object.currentState!]} />
  }

  public isInstrumentOwner(currentLaboratory: Laboratory): boolean {
    if (this.object.ownerableType === 'Laboratory' && this.object.ownerableId === currentLaboratory.id) {
      return true
    } else {
      return false
    }
  }

  public getMakerName(): string {
    return this.object.instrumentBasicInfo?.maker || '-'
  }

  public getManagerName(): string {
    return this.object.managerName || '-'
  }

  public getDealerName(): string {
    return this.object.dealerName || '-'
  }

  public getOutsideDealerContactDetail(): string {
    return this.object.outsideDealerContactDetail || '-'
  }

  public roomName(): string {
    return this.object.roomName || '-'
  }

  public instrumentCategoryName(): string {
    return this.object.instrumentBasicInfo?.instrumentCategoryName || '-'
  }

  public ownerableName(): ReactNode {
    if (isOwnerableOutsideLaboratory(this.object, this.object.ownerable)) {
      return this.object.ownerable.state === 'disable' ? (
        <div>
          <DeletedLabel style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          <div>{this.object.ownerable.name}</div>
        </div>
      ) : (
        this.object.ownerable.name || ''
      )
    } else {
      return this.object.ownerable?.name || '-'
    }
  }

  public dealerDivisionNames(): string {
    const divisionNames = this.object.dealerDivisions?.map((d) => d.name)
    return divisionNames?.join(' / ') || '-'
  }

  public getLink(): React.ReactNode {
    return (
      <a href={this.object.instrumentBasicInfo?.externalLink} target="_blank" rel="noopener noreferrer">
        {this.object.instrumentBasicInfo?.externalLink}
      </a>
    )
  }

  public getExternalLink(): React.ReactNode {
    const externalLink = this.object.instrumentBasicInfo?.externalLink
    const externalLinkName = this.object.instrumentBasicInfo?.externalLinkName

    if (externalLink && externalLinkName) {
      // リンク情報が設定されている場合は Link コンポーネントを返却する
      return (
        <Link target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', fontSize: 12 }} href={externalLink}>
          {externalLinkName}
        </Link>
      )
    } else {
      return null
    }
  }

  public getInstrumentName = () => {
    return this.object.instrumentBasicInfo?.name || '-'
  }

  public getInstrumentBaseInfoIncludeMaker = (linkDisabled?: boolean) => {
    const maker = this.getMakerName()
    const modelNumber = this.object.instrumentBasicInfo?.modelNumber || '-'
    const manageNumber = this.object.instrumentBasicInfo?.manageNumber || '-'
    return (
      <span style={{ fontSize: 12 }}>
        {`${maker} / ${modelNumber} / ${manageNumber}`}
        <LinkedInstrumentName
          instrumentId={this.object.id}
          instrumentName={this.getInstrumentName()}
          ownerableType={this.object.instrumentInfoAttribute?.ownerableType}
          linkDisabled={linkDisabled}
        />
      </span>
    )
  }

  public getInstrumentBaseInfo = (linkDisabled?: boolean) => {
    return (
      <span style={{ fontSize: 12 }}>
        {this.getInstrumentNumbers()}
        <LinkedInstrumentName
          instrumentId={this.object.id}
          instrumentName={this.getInstrumentName()}
          ownerableType={this.object.instrumentInfoAttribute?.ownerableType}
          linkDisabled={linkDisabled}
        />
      </span>
    )
  }

  public getProofreadAlarmDate = () => {
    const alarm = this.object.proofreadAlarm
    const expiredAt = this.object.proofreadInfo?.expiredAt

    if (alarm && alarm.unit && alarm.value && expiredAt) {
      const date = new Date(expiredAt)
      switch (alarm.unit) {
        case 'day':
          date.setDate(date.getDate() - alarm.value)
          break
        case 'week':
          date.setDate(date.getDate() - alarm.value * 7)
          break
        case 'month':
          date.setMonth(date.getMonth() - alarm.value)
          break
      }
      return moment(date).format('YYYY/MM/DD')
    } else {
      return null
    }
  }

  public getAlarmExceededLabel = () => {
    const alarmDateStr = this.getProofreadAlarmDate()
    if (alarmDateStr) {
      const now = new Date()
      const alarmDate = new Date(alarmDateStr)
      const diff = now.getTime() - alarmDate.getTime()
      const exceedDateCount = Math.floor(diff / MSEC_OF_DAY)
      if (diff > 0 && exceedDateCount >= 1) {
        return (
          <Flex alignItems="center" justifyContent="center">
            <WarningIcon color="error" style={{ fontSize: 15, marginRight: 3 }} />
            <div style={{ color: 'red', fontWeight: 'bold' }}>{`超過：${exceedDateCount}日`}</div>
          </Flex>
        )
      }
    }
    return null
  }
}

import { BaseEntity } from 'entities'
import { ApprovalCondition } from 'entities/approval_conditon'
import { Approver } from 'entities/approver'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = ['id', 'laboratoryId', 'name', 'selfApproval'] as const

type OriginalKeyTypeMap = BaseEntity & {
  laboratoryId?: number
  name?: string
  selfApproval?: boolean
};

// モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K];
};

/**
 * 承認設定
 */
export type ApprovalSetting = OriginalProperties & {
  // リレーションやロジックのために付随する属性
  approvers?: Approver[]
  conditions?: ApprovalCondition[]
  proceeding?: boolean
}

const formKeys = ['conditionsAttributes', 'approversAttributes', 'setCondAttr', 'setApprAttr', 'isSet'] as const

type FormKeyTypeMap = {
  conditionsAttributes: ApprovalCondition[]
  approversAttributes: Approver[]
  setCondAttr: (ApprovalCondition & { _destroy?: boolean })[]
  setApprAttr: (Approver & { _destroy?: boolean })[]
  isSet: boolean
}

type FormProperties = {
  [K in typeof formKeys[number]]: FormKeyTypeMap[K];
};

/**
 * フォームで用いる属性。定義していない属性は型エラーで弾く
 */
export type ApprovalSettingForm = OriginalProperties & FormProperties

/**
 * 送信するフォームのキー。ここにないものは送信されない
 */
export const ApprovalSettingFormKeys: (keyof ApprovalSettingForm)[] = [...orginalKeys, ...formKeys]

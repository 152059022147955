import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { InstrumentCategory, InstrumentCategoryForm, InstrumentCategoryFormKeys, InstrumentCategorySearchForm } from 'entities/instrument_category'
import { pick } from 'lodash'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'


type InstrumentCategoriesResponse = PagingResponse & {
  instrumentCategories: InstrumentCategory[]
}

export function usePartnerFetchInstrumentCategoriesApi(): IndexApiSet<InstrumentCategoriesResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<InstrumentCategoriesResponse, InstrumentCategorySearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrumentCategories: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'instrument_categories'
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

type InstrumentCategoryResponse = BaseResponse & {
  instrumentCategory: InstrumentCategory
}

export function usePartnerPostInstrumentCategoryApi(): ApiSet<InstrumentCategoryResponse> & {
  execute: (instrumentCategoryForm: Form<InstrumentCategoryForm>) => void
} {
  const api = useRepruaPostApi<InstrumentCategoryResponse, InstrumentCategoryForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrumentCategory: {} },
  })

  const execute = (instrumentCategoryForm: Form<InstrumentCategoryForm>) => {
    const path = 'instrument_categories'
    api.execute(path, instrumentCategoryForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePartnerPatchInstrumentCategoryApi(): ApiSet<InstrumentCategoryResponse> & {
  execute: (instrumentCategoryForm: InstrumentCategoryForm) => void
} {
  const api = useRepruaPatchApi<InstrumentCategoryResponse, InstrumentCategoryForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrumentCategory: {} },
  })

  const execute = (instrumentCategoryForm: InstrumentCategoryForm) => {
    const path = `instrument_categories/${instrumentCategoryForm.id}`
    api.execute(path, pick(instrumentCategoryForm, InstrumentCategoryFormKeys))
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function usePartnerDeleteInstrumentCategoryApi(): ApiSet<InstrumentCategoryResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<InstrumentCategoryResponse>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { instrumentCategory: {} },
  })

  const execute = (id: ID) => {
    const path = `instrument_categories/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

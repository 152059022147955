import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaPatchApi, useRepruaPostApi, useRepruaShowApi } from 'api/shared_api_hooks'
import { ID } from 'entities'
import { Reservation, ReservationForm, ReservationFormKeys } from 'entities/reservation'
import { pick } from 'lodash'
import { ApiSet, Form, BaseResponse } from 'rac'


type ReservationResponse = BaseResponse & {
  reservation: Reservation
}

/**
 * 取得
 */
export function useFetchReservationApi(): ApiSet<ReservationResponse> & { execute: (id: ID) => void } {
  const api = useRepruaShowApi<ReservationResponse, { id: ID }>(new AxiosHttpClient('user'), { initialResponse: { reservation: {} } })

  const execute = (id: ID) => {
    const path = `reservations/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}

/**
 * 作成
 */
export function usePostReservationApi(): ApiSet<ReservationResponse> & { execute: (reservationForm: Form<ReservationForm>) => void } {
  const api = useRepruaPostApi<ReservationResponse, ReservationForm>(new AxiosHttpClient('user'), { initialResponse: { reservation: {} } })

  const execute = (reservationForm: Form<ReservationForm>) => {
    const path = 'reservations'
    api.execute(path, reservationForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchReservationApi(): ApiSet<ReservationResponse> & { execute: (reservationForm: ReservationForm) => void } {
  const api = useRepruaPatchApi<ReservationResponse, ReservationForm>(new AxiosHttpClient('user'), { initialResponse: { reservation: {} } })

  const execute = (reservationForm: ReservationForm) => {
    const path = `reservations/${reservationForm.id}`
    api.execute(path, pick(reservationForm, ReservationFormKeys))
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteReservationApi(): ApiSet<ReservationResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<ReservationResponse>(new AxiosHttpClient('user'), { initialResponse: { reservation: {} } })

  const execute = (id: ID) => {
    const path = `reservations/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

import { AxiosHttpClient } from 'api/axios'
import { useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi, useRepruaShowApi } from 'api/shared_api_hooks'
import { PagingResponse } from 'entities'
import { OwnerFilter } from 'entities/dealer'
import { getRepairEstimateSearchQuery, RepairEstimate, RepairEstimateForm, RepairEstimateFormKeys, RepairEstimateSearchForm } from 'entities/repair_estimate'
import { pick } from 'lodash'
import { ApiSet, BaseResponse, Form, IndexApiSet } from 'rac'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter'
import { useEffectSkipFirst } from 'utils/hooks'

type RepairEstimatesResponse = PagingResponse & {
  repairEstimates: RepairEstimate[]
}
/**
 * 一覧
 */
export function usePartnerFetchRepairEstimatesApi(
  searchForm: Form<RepairEstimateSearchForm>,
): IndexApiSet<RepairEstimatesResponse> & {
  execute: () => void
} {
  const [filter] = useRecoilState(ownerFilterAtom)
  const api = useRepruaIndexApi<RepairEstimatesResponse, RepairEstimateSearchForm & OwnerFilter>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { repairEstimates: [], totalCount: 0 },
  })
  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'repair_estimates'
    api.execute(path, { params: { ...rest, ...filter }, searchQuery: getRepairEstimateSearchQuery(searchText) })
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy, filter])

  return { ...api, execute }
}

type RepairEstimateResponse = BaseResponse & {
  repairEstimate: RepairEstimate
}
/**
 * 取得
 */
export function usePartnerFetchRepairEstimateApi(
  id: number,
): ApiSet<RepairEstimateResponse> & {
  execute: () => void
} {
  const api = useRepruaShowApi<RepairEstimateResponse, RepairEstimateForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { repairEstimate: {} },
  })

  const execute = () => {
    const path = `repair_estimates/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 作成
 */
export function usePostRepairEstimateApi(
  repairEstimateForm: Form<RepairEstimateForm>,
): ApiSet<RepairEstimateResponse> & {
  execute: () => void
} {
  const api = useRepruaPostApi<RepairEstimateResponse, RepairEstimateForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { repairEstimate: {} },
  })

  const execute = () => {
    const path = 'repair_estimates'
    api.execute(path, repairEstimateForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePartnerPatchRepairEstimateApi(
  repairEstimateForm: Form<RepairEstimateForm>,
  id: number,
): ApiSet<RepairEstimateResponse> & {
  execute: () => void
} {
  const api = useRepruaPatchApi<RepairEstimateResponse, RepairEstimateForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { repairEstimate: {} },
  })

  const execute = () => {
    const path = `repair_estimates/${id}`
    api.execute(path, pick(repairEstimateForm.object, RepairEstimateFormKeys))
  }

  return { ...api, execute }
}

import { BaseEntity, PagingResponse, ID } from 'entities'
import { Instrument } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import { RepairEvent } from 'entities/repair_event'
import { ReservationTerm } from 'entities/reservation_term'
import { Room } from 'entities/room'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = ['id', 'reservableId', 'reservableType', 'userId', 'startAt', 'endAt', 'usageTime', 'reservedUserName', 'comment', 'allDay', 'kind'] as const

type OriginalKeyTypeMap = BaseEntity & {
  reservableId?: number
  reservableType?: string
  userId?: ID
  startAt?: string
  endAt?: string
  usageTime?: number
  reservedUserName?: string
  comment?: string
  allDay?: boolean
  kind?: 'use' | 'repair' | 'proofread'
}

  // モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K]
}
/**
 * 予約
 */
export type Reservation = OriginalProperties & {
  // リレーションやロジックのために付随する属性
  isDisabledEdit?: boolean
  isDisabledCancel?: boolean
  isDisabledEditStartAt?: boolean
  cancelTooltipText?: string
  editTooltipText?: string
  laboratory?: Laboratory
  laboratoryId?: ID
  reservationTerm?: ReservationTerm
  reservableName?: string
  reservableCanReserve?: boolean
  repairEvent?: RepairEvent
  proofreadEventId?: number
  proofreadPlanId?: number
}

const formKeys = [] as const

type FormKeyTypeMap = {}

type FormProperties = {
  [K in typeof formKeys[number]]?: FormKeyTypeMap[K];
}

/**
 * フォームで用いる属性。定義していない属性は型エラーで弾く
 */
export type ReservationForm = OriginalProperties & FormProperties

/**
 * 送信するフォームのキー。ここにないものは送信されない
 */
export const ReservationFormKeys: (keyof ReservationForm)[] = [...orginalKeys, ...formKeys]

export type Reservable = Instrument | Room

export type ReservationSearchForm = {
  startAt?: string
  endAt?: string
}

/** 予約一覧取得の引数 */
export type FetchUserReservationsParams = ReservationSearchForm & {
  roomId?: number
  instrumentCategoryId?: number
}

export type ManagerReservationSearchForm = ReservationSearchForm & {
  laboratoryId?: number
}

export type ReservationsResponse = PagingResponse & {
  reservations: Reservation[]
  managingLaboratoryIds?: number[]
}

export type SelectQuery = 'personal' | 'group'

import { BaseEntity } from 'entities'
import { InstrumentOwnerableType } from 'entities/instrument'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = ['id', 'ownerableId', 'ownerableType', 'clientableId', 'clientableType', 'extensionClass', 'name', 'inputType', 'options', 'required', 'stashed', 'sortNumber', 'visibleOnTable', 'shared'] as const

type OriginalKeyTypeMap = BaseEntity & {
  ownerableId?: number
  ownerableType?: InstrumentOwnerableType
  clientableId?: number | null
  clientableType?: InstrumentOwnerableType | null
  extensionClass?: ExtensionClass
  name?: string
  inputType?: InputType
  options?: string[]
  required?: boolean
  stashed?: boolean
  sortNumber?: number
  visibleOnTable?: boolean
  shared?: boolean
}

// モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K]
}

export type ExColumn = OriginalProperties & {
  // リレーションやロジックのために付随する属性
}

const formKeys = [] as const

type FormKeyTypeMap = {}

type FormProperties = {
  [K in typeof formKeys[number]]?: FormKeyTypeMap[K];
}

export type ExColumnForm = OriginalProperties & FormProperties

export const ExColumnFormKeys: (keyof ExColumnForm)[] = [...orginalKeys, ...formKeys]

export const inputType = ['string', 'text', 'integer', 'dropdown', 'price'] as const
export type InputType = typeof inputType[number]

const extensionClass = ['InstrumentInfoAttribute', 'ProofreadEvent'] as const
export type ExtensionClass = typeof extensionClass[number]

import { BaseEntity, ID } from 'entities'
import { AttachmentAttributes } from 'entities/attachment_attributes'
import { DefectReport } from 'entities/defect_report'
import { ExAttribute } from 'entities/ex_attribute'
import { Instrument } from 'entities/instrument'
import { Laboratory } from 'entities/laboratory'
import { ProofreadEvent } from 'entities/proofread_event'
import { RepairEstimate } from 'entities/repair_estimate'
import { RepairEventAttachment } from 'entities/repair_event_attachment'
import { SearchQuery } from 'rac'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = [
    'id', 'instrumentId', 'defectReportId', 'ownerableId', 'ownerableType', 'recorderableId', 'recorderableType', 'suppliableId', 'suppliableType', 'proofreadEventId', 'repairInfoId',
    'aasmState', 'startAt', 'endAt', 'price', 'noteToSupplier', 'detail', 'repairUserName',
  ] as const

type OriginalKeyTypeMap = BaseEntity & {
  instrumentId?: ID
  defectReportId?: ID
  ownerableId?: number
  ownerableType?: OwnerableType
  recorderableId?: ID
  recorderableType?: 'User' | 'DealerUser'
  suppliableId?: ID
  suppliableType?: string
  proofreadEventId?: ID
  repairInfoId?: ID
  aasmState?: string
  startAt?: string
  endAt?: string
  price?: number
  noteToSupplier?: string
  detail?: string
  repairUserName?: string
}

// モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K]
}

/**
 * 修理イベント
 */
export type RepairEvent = OriginalProperties & {
  // リレーションやロジックのために付随する属性
  recorderName?: string
  supplierName?: string
  tableExAttrs?: ExAttribute[]
  proofreadEvent?: ProofreadEvent
  fileAttachments?: RepairEventAttachment[]
  instrument?: Instrument
  defectReport?: DefectReport
  repairEstimate?: RepairEstimate
  laboratory?: Laboratory
}

const formKeys = ['fileAttachments', 'attachmentsAttributes', 'skipFixResult'] as const

type FormKeyTypeMap = {
  fileAttachments?: RepairEventAttachment[]
  attachmentsAttributes?: AttachmentAttributes[]
  skipFixResult?: boolean
}


type FormProperties = {
  [K in typeof formKeys[number]]?: FormKeyTypeMap[K];
}

/**
 * フォームで用いる属性。定義していない属性は型エラーで弾く
 */
export type RepairEventForm = OriginalProperties & FormProperties

/**
 * 送信するフォームのキー。ここにないものは送信されない
 */
export const RepairEventFormKeys: (keyof RepairEventForm)[] = [...orginalKeys, ...formKeys]

type OwnerableType = 'Laboratory' | 'Dealer'

export type RepairEventSearchForm = {
  searchText?: string
}

export const getRepairEventSearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: [
    'noteToSupplier',
    'detail',
    ['repairInfoDefectReport', { attrs: ['point', 'content'] }],
    ['suppliable', { attrs: ['name'], polymorphicType: 'OutsideLaboratory' }],

    // FIXME: multiDBの不具合暫定対応
    // ['ownerable', { attrs: ['name'], polymorphicType: 'Laboratory' }],
    // ['ownerable', { attrs: ['name'], polymorphicType: 'Dealer' }],
    // ['recorderable', { attrs: ['familyName', 'givenName'], polymorphicType: 'User' }],
    // ['recorderable', { attrs: ['name'], polymorphicType: 'DealerUser' }],
  ],
})

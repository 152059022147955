import { useFetchProofreadPlanApi } from 'api/mypage/proofread_plan'
import { useFetchProofreadSchedulesApi } from 'api/mypage/proofread_plans/proofread_schedule'
import { useExportProofreadEventsApi, useFetchProofreadEventsApi } from 'api/mypage/proofread_plans/proofread_event'
import { useFetchExColumnsApi } from 'api/shared/ex_columns'
import ApprovedPlanEventTable from 'components/mypage/proofread/proofread_event/approved_plan_event_table'
import ApprovedResultEventTable from 'components/mypage/proofread/proofread_event/approved_result_event_table'
import AttachedResultEventTable from 'components/mypage/proofread/proofread_event/attached_result_event_table'
import CreateEventModal from 'components/mypage/proofread/proofread_event/create_event_modal'
import { EventHistoryModal } from 'components/mypage/proofread/proofread_event/event_history_modal'
import FixDateEventTable from 'components/mypage/proofread/proofread_event/fix_date_event_table'
import FixResultEventTable from 'components/mypage/proofread/proofread_event/fix_result_event_table'
import NoPlanEventTable from 'components/mypage/proofread/proofread_event/no_plan_event_table'
import PlannedEventTable from 'components/mypage/proofread/proofread_event/planned_event_table'
import { ContentHeader, ContentTitle } from 'components/shared/content'
import { ExAttributeBulkUpdateFormDialog, ExAttributeFormDialog } from 'components/shared/ex_attribute/ex_attribute_form_dialog'
import { useModal, useMultipleModal } from 'components/shared/modal'
import ProofreadStepTab, { StepTab } from 'components/shared/proofread_step_tab'
import ThemedButton from 'components/shared/themed_button'
import { GlobalStateContext } from 'contexts/global_state_context'
import { ExColumn } from 'entities/ex_column'
import {
  MYPAGE_PROOFREAD_MINIMUM_STEPS,
  ProofreadEvent,
  ProofreadEventSearchForm,
  ProofreadEventState,
  proofreadEventStateTexts,
} from 'entities/proofread_event'
import { useForm } from 'rac'
import React, { useEffect, useState, useContext } from 'react'
import { StaticContext } from 'react-router'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CookieManager from 'utils/cookie_util'
import { useEffectSkipFirst } from 'utils/hooks'
import { tabTypes } from 'utils/tab_types'

const clientType = 'user'
const exAttributeExtensionableType = 'ProofreadEvent'

type Props = RouteComponentProps<{ id: string }, StaticContext>
export const ProofreadPlanShow = (props: Props) => {
  const globalState = useContext(GlobalStateContext)
  const showApi = useFetchProofreadPlanApi(Number(props.match.params.id))

  const noPlanSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['no_plan'] } }, 'proofreadEventForm')
  const noPlanApi = useFetchProofreadEventsApi(Number(props.match.params.id), noPlanSearchForm)

  const plannedSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['planned'] } }, 'proofreadEventForm')
  const plannedApi = useFetchProofreadEventsApi(Number(props.match.params.id), plannedSearchForm)

  const approvedPlanForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['approved_plan'] } }, 'proofreadEventForm')
  const approvedPlanApi = useFetchProofreadSchedulesApi(Number(props.match.params.id), approvedPlanForm)

  const fixDateSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['fix_date'] } }, 'proofreadEventForm')
  const fixDateApi = useFetchProofreadEventsApi(Number(props.match.params.id), fixDateSearchForm)

  const attachedResultSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['attached_result'] } }, 'proofreadEventForm')
  const attachedResultApi = useFetchProofreadEventsApi(Number(props.match.params.id), attachedResultSearchForm)

  const fixResultSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['fix_result'] } }, 'proofreadEventForm')
  const fixResultApi = useFetchProofreadEventsApi(Number(props.match.params.id), fixResultSearchForm)

  const approvedResultSearchForm = useForm<ProofreadEventSearchForm>({ q: { aasmStateIn: ['approved_result'] } }, 'proofreadEventForm')
  const approvedResultApi = useFetchProofreadEventsApi(Number(props.match.params.id), approvedResultSearchForm)

  const exportApi = useExportProofreadEventsApi(Number(props.match.params.id))
  const indexExColumnApi = useFetchExColumnsApi(clientType)
  const [exColumns, setExColumns] = useState<ExColumn[]>([])
  const [tableShowColumns, setTableShowColumns] = useState<ExColumn[]>([])

  const [step, setStep] = useState<ProofreadEventState>(
    MYPAGE_PROOFREAD_MINIMUM_STEPS.includes(
      CookieManager.getSelectedTab(tabTypes.MYPAGE_PROOFREAD) || MYPAGE_PROOFREAD_MINIMUM_STEPS[0],
    )
      ? CookieManager.getSelectedTab(tabTypes.MYPAGE_PROOFREAD) || MYPAGE_PROOFREAD_MINIMUM_STEPS[0]
      : MYPAGE_PROOFREAD_MINIMUM_STEPS[0],
  )
  const createEventModal = useModal()
  const exColumnModalSet = useMultipleModal<ProofreadEvent>()
  const eventHistoryModal = useMultipleModal<ProofreadEvent>()
  const exColumnBulkUpdateModalSet = useMultipleModal<number[]>()

  // ディーラが作成した校正計画かどうか
  const isPlanCreatedByDealer = showApi.response.proofreadPlan.ownerableType === 'Dealer'

  const refetchTable = () => {
    showApi.execute()
    noPlanApi.execute()
    plannedApi.execute()
    approvedPlanApi.execute()
    fixDateApi.execute()
    attachedResultApi.execute()
    fixResultApi.execute()
    approvedResultApi.execute()
  }

  const handleClickExAttr = (event: ProofreadEvent) => {
    setExColumns(indexExColumnApi.response.exColumns.filter((c) => c.extensionClass === exAttributeExtensionableType))
    exColumnModalSet.openModal(event)
  }
  const handleClickBulkExAttr = (eventIds: number[]) => {
    setExColumns(indexExColumnApi.response.exColumns.filter((c) => c.extensionClass === exAttributeExtensionableType))
    exColumnBulkUpdateModalSet.openModal(eventIds)
  }

  const handleUpdate = () => refetchTable()

  useEffect(() => {
    refetchTable()
    indexExColumnApi.execute()
  }, [])

  const handleClickAddInstrument = () => {
    createEventModal.setOpen(true)
  }
  const handleCompleteCreate = () => {
    createEventModal.setOpen(false)
    refetchTable()
  }

  const handleCompleteExAttr = () => {
    exColumnModalSet.closeModal()
    exColumnBulkUpdateModalSet.closeModal()
    refetchTable()
  }

  const handleClickHistory = (event: ProofreadEvent) => {
    eventHistoryModal.openModal(event)
  }

  const handleSelectStep = (step: ProofreadEventState) => {
    CookieManager.saveSelectedTab(tabTypes.MYPAGE_PROOFREAD, step)
    setStep(step)
  }

  useEffectSkipFirst(() => {
    if (!indexExColumnApi.isSuccess()) return
    setTableShowColumns(indexExColumnApi.response.exColumns.filter((col) => col.visibleOnTable))
  }, [indexExColumnApi.loading])

  /**
   * ローディングプログレスの表示
   */
  useEffect(() => {
    globalState.setLoading(showApi.loading)
  }, [showApi.loading])

  const stepData: StepTab[] = [
    {
      id: 'no_plan',
      label: proofreadEventStateTexts.no_plan,
      component: (
        <NoPlanEventTable
          planId={Number(props.match.params.id)}
          isPlanCreatedByDealer={isPlanCreatedByDealer}
          searchForm={noPlanSearchForm}
          indexApi={noPlanApi}
          tableShowColumns={tableShowColumns}
          onUpdate={handleUpdate}
          onClickHistory={handleClickHistory}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
        />
      ),
    },
    {
      id: 'planned',
      label: proofreadEventStateTexts.planned,
      component: (
        <PlannedEventTable
          indexApi={plannedApi}
          searchForm={plannedSearchForm}
          tableShowColumns={tableShowColumns}
          onUpdate={handleUpdate}
          onClickHistory={handleClickHistory}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
        />
      ),
    },
    {
      id: 'approved_plan',
      label: proofreadEventStateTexts.approved_plan,
      component: <ApprovedPlanEventTable indexApi={approvedPlanApi} onUpdate={handleUpdate} planId={Number(props.match.params.id)} />,
    },
    {
      id: 'fix_date',
      label: proofreadEventStateTexts.fix_date,
      component: <FixDateEventTable indexApi={fixDateApi} onUpdate={handleUpdate} />,
    },
    {
      id: 'attached_result',
      label: proofreadEventStateTexts.attached_result,
      component: (
        <AttachedResultEventTable
          planId={Number(props.match.params.id)}
          indexApi={attachedResultApi}
          searchForm={attachedResultSearchForm}
          tableShowColumns={tableShowColumns}
          onUpdate={handleUpdate}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
        />
      ),
    },
    {
      id: 'fix_result',
      label: proofreadEventStateTexts.fix_result,
      component: (
        <FixResultEventTable
          indexApi={fixResultApi}
          searchForm={fixResultSearchForm}
          tableShowColumns={tableShowColumns}
          onUpdate={handleUpdate}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
        />
      ),
    },
    {
      id: 'approved_result',
      label: proofreadEventStateTexts.approved_result,
      component: (
        <ApprovedResultEventTable
          indexApi={approvedResultApi}
          searchForm={approvedResultSearchForm}
          tableShowColumns={tableShowColumns}
          onUpdate={handleUpdate}
          onClickExAttr={handleClickExAttr}
          onClickBulkExAttr={handleClickBulkExAttr}
        />
      ),
    },
  ]

  // テストディーラ社代行
  const getTitle = () => {
    const baseTitle = `校正計画 - ${showApi.response.proofreadPlan.title || ''}`
    if (isPlanCreatedByDealer) {
      return `${baseTitle} - ${showApi.response.proofreadPlan.dealer?.name} 代行`
    } else {
      return baseTitle
    }
  }

  // ステップタブを取得するためのメソッド
  const getStepTab = () => {
    if (isPlanCreatedByDealer) {
      // ディーラが作成した校正計画の場合
      // 計画時に承認者を設定することができないため、承認プロセスは不要になる
      return stepData.filter((d) => d.id !== 'planned' && d.id !== 'fix_result')
    } else {
      // ラボが作成した校正計画の場合
      if (!showApi.response.proofreadPlan.requireSupplier) {
        // 共有中の機器が計画に含まれている場合
        return stepData.filter((d) => d.id !== 'approved_plan' && d.id !== 'fix_date')
      } else {
        return stepData
      }
    }
  }

  return (
    <>
      {showApi.isSuccess() && (
        <>
          <CreateEventModal modalState={createEventModal} planId={Number(props.match.params.id)} onComplete={handleCompleteCreate} />
          <EventHistoryModal modalSet={eventHistoryModal} />
          <ExAttributeFormDialog
            clientType={clientType}
            modalSet={exColumnModalSet}
            extensionClass={exAttributeExtensionableType}
            exAttributes={exColumnModalSet.item.exAttributes || []}
            onComplete={handleCompleteExAttr}
          />
          <ExAttributeBulkUpdateFormDialog
            clientType="user"
            modalSet={exColumnBulkUpdateModalSet}
            extensionClass={exAttributeExtensionableType}
            exColumns={exColumns}
            onComplete={handleCompleteExAttr}
          />
          <ContentHeader>
            <ContentTitle title={getTitle()}>
              {step === 'no_plan' && !isPlanCreatedByDealer && (
                <ThemedButton color="primary" onClick={handleClickAddInstrument}>
                  新しく機器を追加する
                </ThemedButton>
              )}
            </ContentTitle>
            <ThemedButton color="secondary" onClick={exportApi.execute}>
              Excel出力
            </ThemedButton>
          </ContentHeader>
          <ProofreadStepTab data={getStepTab()} currentStepId={step} plan={showApi.response.proofreadPlan} onSelect={handleSelectStep} />
        </>
      )}
    </>
  )
}
export default withRouter(ProofreadPlanShow)

import { ViewModel } from 'view_models'

import { MemoBase } from './memo_base'

export type UserMemo = MemoBase & {
  userMemoableId?: number
  userMemoableType?: string
  userName?: string
}

export type UserMemoForm = UserMemo & {}

// TODO: 編集フォームを実装する際は entities/instrument.ts のコメントと実装を参考に、同様の構造で実装する

export class UserMemoViewModel extends ViewModel<UserMemo> {}

import { BaseEntity } from '.'

/**
 * ユーザーメール通知設定
 */
export type UserMailNotificationSetting = BaseEntity & {
  instrumentReservation?: boolean
}

export type UserMailNotificationSettingForm = UserMailNotificationSetting

// TODO: 編集フォームを実装する際は entities/instrument.ts のコメントと実装を参考に、同様の構造で実装する

export const userMailNotificationSettings: { id: string; displayName: string; description: string }[] = [
  { id: 'instrument', displayName: '機器予約', description: '自分以外のユーザが機器を予約・変更・取消した際にメール通知します' },
]

export const initialFormState = {
  userAuthorities: [],
}

import { AxiosHttpClient } from 'api/axios'
import { useRepruaDeleteApi, useRepruaIndexApi, useRepruaPatchApi, useRepruaPostApi } from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { OwnerFilter } from 'entities/dealer';
import { OutsideLaboratory, OutsideLaboratoryForm, OutsideLaboratoryFormKeys, OutsideLaboratorySearchForm } from 'entities/outside_laboratory'
import { pick } from 'lodash';
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'
import { useRecoilState } from 'recoil'
import { ownerFilterAtom } from 'recoil/partner/owner_filter'
import { useEffectSkipFirst } from 'utils/hooks'


type OutsideLaboratoriesResponse = PagingResponse & {
  outsideLaboratories: OutsideLaboratory[]
}

/**
 * 外部顧客一覧
 * @param fetchAll: boolean 担当顧客以外の全ての顧客を取得するかどうか
 * @param includesDisable: boolean 
 * @param useFilter: boolean 顧客フィルターを適用するか
 */
export function usePartnerFetchOutsideLaboratoriesApi(
  { fetchAll = false,
    includesDisable = false,
    useFilter = false },
): IndexApiSet<OutsideLaboratoriesResponse> & { execute: () => void } {
  const [filter] = useRecoilState(ownerFilterAtom)
  const api = useRepruaIndexApi<OutsideLaboratoriesResponse, OutsideLaboratorySearchForm & OwnerFilter>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { outsideLaboratories: [], totalCount: 0 },
  })

  const execute = () => {
    const path = `outside_laboratories?fetch_all=${fetchAll}&includes_disable=${includesDisable}&use_filter=${useFilter}`
    api.execute(path, { params: useFilter ? filter : {} })
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  useEffectSkipFirst(() => {
    if (useFilter) {
      execute()
    }
  }, [filter])

  return { ...api, execute }
}

export function usePartnerFetchDisabledOutsideLaboratoriesApi(): IndexApiSet<OutsideLaboratoriesResponse> & { execute: () => void } {
  const api = useRepruaIndexApi<OutsideLaboratoriesResponse, OutsideLaboratorySearchForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { outsideLaboratories: [], totalCount: 0 },
  })

  const execute = () => {
    const path = 'disabled_outside_laboratories'
    api.execute(path)
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute }
}

type OutsideLaboratoryResponse = BaseResponse & {
  outsideLaboratory: OutsideLaboratory
}

export function usePartnerPostOutsideLaboratoryApi(): ApiSet<OutsideLaboratoryResponse> & {
  execute: (outsideLaboratoryForm: Form<OutsideLaboratoryForm>) => void
} {
  const api = useRepruaPostApi<OutsideLaboratoryResponse, OutsideLaboratoryForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { outsideLaboratory: {} },
  })

  const execute = (outsideLaboratoryForm: Form<OutsideLaboratoryForm>) => {
    const path = 'outside_laboratories'
    api.execute(path, outsideLaboratoryForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePartnerPatchOutsideLaboratoryApi(): ApiSet<OutsideLaboratoryResponse> & {
  execute: (outsideLaboratoryForm: OutsideLaboratoryForm) => void
} {
  const api = useRepruaPatchApi<OutsideLaboratoryResponse, OutsideLaboratoryForm>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { outsideLaboratory: {} },
  })

  const execute = (outsideLaboratoryForm: OutsideLaboratoryForm) => {
    const path = `outside_laboratories/${outsideLaboratoryForm.id}`
    api.execute(path, pick(outsideLaboratoryForm, OutsideLaboratoryFormKeys))
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function usePartnerDeleteOutsideLaboratoryApi(): ApiSet<OutsideLaboratoryResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<OutsideLaboratoryResponse>(new AxiosHttpClient('dealer_user'), {
    initialResponse: { outsideLaboratory: {} },
  })

  const execute = (id: ID) => {
    const path = `outside_laboratories/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

import { AxiosHttpClient } from 'api/axios'
import { useRepruaPatchApi } from 'api/shared_api_hooks'
import { ProofreadSchedule, ProofreadScheduleForm, ProofreadScheduleFormKeys } from 'entities/proofread_schedule'
import { pick } from 'lodash'
import { ApiSet, BaseResponse, Form } from 'rac'

export type ProofreadScheduleResponse = BaseResponse & {
  proofreadSchedule: ProofreadSchedule
}

/**
 * 編集
 */
export const useUpdateProofreadScheduleApi = (
  form: Form<ProofreadScheduleForm>,
): ApiSet<ProofreadScheduleResponse> & { execute: () => void } => {
  const api = useRepruaPatchApi<ProofreadScheduleResponse, ProofreadScheduleForm>(new AxiosHttpClient('user'), {
    initialResponse: { proofreadSchedule: {} },
  })

  const execute = () => {
    const path = `proofread_schedules/${form.object.id}`
    api.execute(path, pick(form.object, ProofreadScheduleFormKeys))
  }

  return { ...api, execute }
}

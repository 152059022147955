import { BaseEntity } from 'entities'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = ['id', 'name'] as const

type OriginalKeyTypeMap = BaseEntity & {
  name?: string
}

// モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K]
}

/**
 * 機器カテゴリ
 */
export type InstrumentCategory = OriginalProperties & {
  // リレーションやロジックのために付随する属性
  instrumentsCount?: number
}

const formKeys = [] as const

type FormKeyTypeMap = {}

type FormProperties = {
  [K in typeof formKeys[number]]?: FormKeyTypeMap[K];
}

export type InstrumentCategoryForm = OriginalProperties & FormProperties

// 送信するフォームのキー。ここにないものは送信されない
export const InstrumentCategoryFormKeys: (keyof InstrumentCategoryForm)[] = [...orginalKeys, ...formKeys]

export type InstrumentCategorySearchForm = {}

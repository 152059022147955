import { AxiosHttpClient } from 'api/axios'
import {
  useRepruaIndexApi,
  useRepruaShowApi,
  useRepruaPatchApi,
  useRepruaDeleteApi,
  useRepruaPostApi,
  useRepruaDownloadApi,
} from 'api/shared_api_hooks'
import { ID, PagingResponse } from 'entities'
import { DefectReport, DefectReportForm, DefectReportFormKeys, DefectReportSearchForm, getDefectReportSearchQuery } from 'entities/defect_report'
import { Instrument } from 'entities/instrument'
import { pick } from 'lodash'
import { IndexApiSet, ApiSet, Form, BaseResponse } from 'rac'
import { useEffectSkipFirst } from 'utils/hooks'

export type DefectReportsResponse = PagingResponse & {
  defectReports: DefectReport[]
  instrument: Instrument
}

/**
 * 一覧
 */
type FetchDefectReportsApiSet = IndexApiSet<DefectReportsResponse> & {
  execute: () => void
  executeAndPageReset: () => void
}
export function useFetchDefectReportsApi(searchForm: Form<DefectReportSearchForm>): FetchDefectReportsApiSet {
  const api = useRepruaIndexApi<DefectReportsResponse, DefectReportSearchForm>(new AxiosHttpClient('user'), {
    initialResponse: { defectReports: [], totalCount: 0, instrument: {} },
  })
  const { searchText, ...rest } = searchForm.object

  const execute = () => {
    const path = 'defect_reports'
    api.execute(path, { params: rest, searchQuery: getDefectReportSearchQuery(searchText) })
  }

  const executeAndPageReset = () => {
    if (api.pageSet.page > 1) {
      api.pageSet.setPage(1)
    } else {
      execute()
    }
  }

  useEffectSkipFirst(() => {
    execute()
  }, [api.pageSet.page, api.pageSet.perPage, api.orderSet.order, api.orderSet.orderBy])

  return { ...api, execute, executeAndPageReset }
}

export type DefectReportResponse = BaseResponse & {
  defectReport: DefectReport
}

/**
 * 取得
 */
export function useFetchDefectReportApi(): ApiSet<DefectReportResponse> & { execute: (id: ID) => void } {
  const api = useRepruaShowApi<DefectReportResponse, { id: ID }>(new AxiosHttpClient('user'), { initialResponse: { defectReport: {} } })

  const execute = (id: ID) => {
    const path = `defect_reports/${id}`
    api.execute(path, { id: id })
  }

  return { ...api, execute }
}

/**
 * 作成
 */
export function usePostDefectReportApi(): ApiSet<DefectReportResponse> & { execute: (defectReportForm: Form<DefectReportForm>) => void } {
  const api = useRepruaPostApi<DefectReportResponse, DefectReportForm>(new AxiosHttpClient('user'), {
    initialResponse: { defectReport: {} },
  })

  const execute = (defectReportForm: Form<DefectReportForm>) => {
    const path = 'defect_reports'
    api.execute(path, defectReportForm)
  }

  return { ...api, execute }
}

/**
 * 更新
 */
export function usePatchDefectReportApi(): ApiSet<DefectReportResponse> & { execute: (defectReportForm: DefectReportForm) => void } {
  const api = useRepruaPatchApi<DefectReportResponse, DefectReportForm>(new AxiosHttpClient('user'), {
    initialResponse: { defectReport: {} },
  })

  const execute = (defectReportForm: DefectReportForm) => {
    const path = `defect_reports/${defectReportForm.id}`
    api.execute(path, pick(defectReportForm, DefectReportFormKeys))
  }

  return { ...api, execute }
}

/**
 * 削除
 */
export function useDeleteDefectReportApi(): ApiSet<DefectReportResponse> & { execute: (id: ID) => void } {
  const api = useRepruaDeleteApi<DefectReportResponse>(new AxiosHttpClient('user'), { initialResponse: { defectReport: {} } })

  const execute = (id: ID) => {
    const path = `defect_reports/${id}`
    api.execute(path)
  }

  return { ...api, execute }
}

/**
 * 出力
 */
export const useExportDefectReportsApi = (searchForm: Form<DefectReportSearchForm>): { execute: (format: 'xlsx') => void } => {
  const api = useRepruaDownloadApi<DefectReportSearchForm>(new AxiosHttpClient('user'))

  const { searchText, ...rest } = searchForm.object

  const execute = (format: 'xlsx') => {
    const path = `defect_reports.${format}`
    api.execute(path, { params: rest, searchQuery: getDefectReportSearchQuery(searchText) })
  }

  return { execute }
}

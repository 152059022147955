import { BaseEntity } from 'entities'
import { ProofreadEvent } from 'entities/proofread_event'
import { ProofreadPlan } from 'entities/proofread_plan'
import { Supplier } from 'entities/supplier'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = ['id', 'suppliableId', 'suppliableType', 'proofreadPlanId', 'deliveredDate', 'returnedDate', 'aasmState', 'note'] as const

type OriginalKeyTypeMap = BaseEntity & {
  suppliableId?: number
  suppliableType?: string
  proofreadPlanId?: number

  deliveredDate?: string
  returnedDate?: string
  aasmState?: 'requested' | 'approved' | 'rejected'
  note?: string
}

// モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K]
}

/**
 * 校正スケジュール
 */
export type ProofreadSchedule = OriginalProperties & {
  // リレーションやロジックのために付随する属性
  supplier?: Supplier
  proofreadPlan?: ProofreadPlan
  proofreadEvents?: ProofreadEvent[]
  targetInstrumentCount?: number
}

const formKeys = ['eventIds'] as const

type FormKeyTypeMap = {
  eventIds?: number[]
}

type FormProperties = {
  [K in typeof formKeys[number]]?: FormKeyTypeMap[K];
}

/**
 * フォームで用いる属性。定義していない属性は型エラーで弾く
 */
export type ProofreadScheduleForm = OriginalProperties & FormProperties

/**
 * 送信するフォームのキー。ここにないものは送信されない
 */
export const ProofreadScheduleFormKeys: (keyof ProofreadScheduleForm)[] = [...orginalKeys, ...formKeys]

export type ProofreadScheduleSearchForm = {}
